<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl font-bold">New Merchant Visit</h1>

      <div class="mt-14">
        <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/2 mx-auto">
          <!-- Column Content -->
          <div
            class="w-full border-2 drop-shadow-sm border-gray-100 flex flex-col bg-white rounded-lg overflow-hidden cursor-pointer"
          >
            <div class="border-t border-gray-200 p-5">
              <p class="font-medium mt-2 text-sm">Schedule Merchant Visit</p>
              <div v-if="firstPage">
                <div class="drop-shadow-2xl rounded-md p-3 mt-5">
                  <p class="font-medium text-xs">
                    Merchant Support Officer <span class="text-red-600">*</span>
                  </p>
                  <p class="text-xs">
                    <select
                      type="text"
                      v-model="supportOfficer"
                      class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
                    >
                      <option value="" disabled>Select Support Officer</option>
                      <option v-for="(officer, i) in supportOfficers" :key="i">
                        {{ officer.agentName }}
                      </option>
                    </select>
                  </p>
                  <p
                    v-if="errors.supportOfficer && suppoprtOfficer === ''"
                    class="text-xs text-red-600 mt-2"
                  >
                    {{ errors.supportOfficer }}
                  </p>
                </div>
                <div class="drop-shadow-2xl rounded-md p-3 mt-3">
                  <p class="font-medium text-xs">
                    Merchant Email Address <span class="text-red-600">*</span>
                  </p>
                  <div class="flex text-xs">
                    <input
                      type="text"
                      v-model="merchantEmail"
                      class="shadow inline-block appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
                    />
                    <button
                      @click="fetchBusinessAsync"
                      class="inline-block ml-2 uppercase shadow bg-blue-600 leading-6 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
                    >
                      Search
                    </button>
                  </div>
                  <p
                    v-if="errors.merchantEmail && merchantEmail === ''"
                    class="text-xs text-red-600 mt-2"
                  >
                    {{ errors.merchantEmail }}
                  </p>
                </div>
                <div class="drop-shadow-2xl rounded-md p-3 mt-3">
                  <p class="font-medium text-xs">
                    Merchant Name <span class="text-red-600">*</span>
                  </p>
                  <p class="text-xs">
                    <input
                      type="text"
                      v-model="merchantName"
                      class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
                    />
                  </p>
                  <p
                    v-if="errors.merchantName && merchantName === ''"
                    class="text-xs text-red-600 mt-2"
                  >
                    {{ errors.merchantName }}
                  </p>
                </div>
                <div class="drop-shadow-2xl rounded-md p-3 mt-3">
                  <p class="font-medium text-xs">
                    Business name <span class="text-red-600">*</span>
                  </p>
                  <p class="text-xs">
                    <input
                      type="text"
                      v-model="businessName"
                      class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
                    />
                  </p>
                  <p
                    v-if="errors.businessName && businessName === ''"
                    class="text-xs text-red-600 mt-2"
                  >
                    {{ errors.businessName }}
                  </p>
                </div>
                <div class="drop-shadow-2xl rounded-md p-3 mt-3">
                  <p class="font-medium text-xs">
                    Business Phone Number <span class="text-red-600">*</span>
                  </p>
                  <p class="text-xs">
                    <input
                      type="text"
                      v-model="businessPhone"
                      class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
                    />
                  </p>
                  <p
                    v-if="errors.businessPhone && businessPhone === ''"
                    class="text-xs text-red-600 mt-2"
                  >
                    {{ errors.businessPhone }}
                  </p>
                </div>
                <div class="drop-shadow-2xl rounded-md p-3 mt-3">
                  <p class="font-medium text-xs">
                    Contact name <span class="text-red-600">*</span>
                  </p>
                  <p class="text-xs">
                    <input
                      type="text"
                      v-model="contactName"
                      class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
                    />
                  </p>
                  <p
                    v-if="errors.contactName && contactName === ''"
                    class="text-xs text-red-600 mt-2"
                  >
                    {{ errors.contactName }}
                  </p>
                </div>
                <div class="drop-shadow-2xl rounded-md p-3 mt-3">
                  <p class="font-medium text-xs">
                    Merchant's Address <span class="text-red-600">*</span>
                  </p>
                  <p class="text-xs">
                    <input
                      type="text"
                      v-model="merchantAddress"
                      class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
                    />
                  </p>
                  <p
                    v-if="errors.merchantAddress && merchantAddress === ''"
                    class="text-xs text-red-600 mt-2"
                  >
                    {{ errors.merchantAddress }}
                  </p>
                </div>
                <div class="p-3">
                  <button
                    @click="handleCancel"
                    class="inline uppercase shadow bg-yellow-600 mt-5 leading-6 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
                  >
                    Cancel
                  </button>
                  <button
                    @click="handleNext"
                    class="inline uppercase shadow ml-5 bg-blue-600 mt-5 leading-6 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
                  >
                    Next
                  </button>
                </div>
              </div>
              <div v-if="secondPage">
                <div class="drop-shadow-2xl rounded-md p-3 mt-3">
                  <p class="font-medium text-xs">
                    Store type <span class="text-red-600">*</span>
                  </p>
                  <p class="text-xs">
                    <input
                      type="text"
                      v-model="storeType"
                      class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
                    />
                  </p>
                  <p
                    v-if="errors.storeType && storeType === ''"
                    class="text-xs text-red-600 mt-2"
                  >
                    {{ errors.storeType }}
                  </p>
                </div>
                <div class="drop-shadow-2xl rounded-md p-3 mt-3">
                  <p class="font-medium text-xs">
                    Terminal ID <span class="text-red-600">*</span>
                  </p>
                  <p class="text-xs">
                    <input
                      type="text"
                      v-model="terminalID"
                      class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
                    />
                  </p>
                  <p
                    v-if="errors.terminalID && terminalID === ''"
                    class="text-xs text-red-600 mt-2"
                  >
                    {{ errors.terminalID }}
                  </p>
                </div>
                <div class="drop-shadow-2xl rounded-md p-3 mt-3">
                  <p class="font-medium text-xs">
                    Date of Scheduled Visit <span class="text-red-600">*</span>
                  </p>
                  <p class="text-xs">
                    <input
                      type="date"
                      v-model="dateOfScheduledVisit"
                      class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
                    />
                  </p>
                  <p
                    v-if="
                      errors.dateOfScheduledVisit && dateOfScheduledVisit === ''
                    "
                    class="text-xs text-red-600 mt-2"
                  >
                    {{ errors.dateOfScheduledVisit }}
                  </p>
                </div>
                <div class="drop-shadow-2xl rounded-md p-3 mt-3">
                  <p class="font-medium text-xs">
                    Visitation Agenda <span class="text-red-600">*</span>
                  </p>
                  <p class="text-xs">
                    <select
                      type="text"
                      v-model="visitationAgenda"
                      class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
                    >
                      <option value="" disabled
                        >Select Visitation Agenda</option
                      >
                      <option value="Regular check-in">Regular check-in</option>
                      <option value="Merchant Complaint"
                        >Merchant Complaint</option
                      >
                      <option value="Inactivity">Inactivity</option>
                    </select>
                  </p>
                  <p
                    v-if="errors.visitationAgenda && visitationAgenda === ''"
                    class="text-xs text-red-600 mt-2"
                  >
                    {{ errors.visitationAgenda }}
                  </p>
                </div>
                <div class="drop-shadow-2xl rounded-md p-3 mt-3">
                  <p class="font-medium text-xs">Additional Comments</p>
                  <p class="text-xs">
                    <textarea
                      rows="5"
                      v-model="additionalComments"
                      class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
                    >
                    </textarea>
                  </p>
                </div>
                <div class="p-3">
                  <button
                    @click="handleBack"
                    class="inline uppercase shadow bg-yellow-600 mt-5 leading-6 hover:bg-yellow-800 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
                  >
                    Back
                  </button>
                  <button
                    @click="handleShowPrompt"
                    class="inline uppercase shadow ml-5 bg-blue-600 mt-5 leading-6 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div
                v-if="promptDiv"
                class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
              >
                <div
                  class="w-1/5 rounded-lg bg-white p-5 grid grid-cols-1 gap-4"
                >
                  <h4 class="font-bold">Schedule Merchant Visit</h4>
                  <p class="text-xs mb-3">
                    Submitting this form will automatically schedule a visit
                    with:
                  </p>
                  <p class="text-xs">
                    Business name:
                    <span class="font-bold">{{ businessName }}</span>
                  </p>
                  <p class="text-xs">
                    Contact name:
                    <span class="font-bold">{{ contactName }}</span>
                  </p>
                  <p class="text-xs">
                    Scheduled Date:
                    <span class="font-bold">{{ dateOfScheduledVisit }}</span>
                  </p>
                  <p class="text-xs">
                    Agenda:
                    <span class="font-bold">{{ visitationAgenda }}</span>
                  </p>
                  <button
                    @click="handleCreateMerchantVisit"
                    class="
                    float-right
                    uppercase
                    shadow
                    mx-auto
                    hover:bg-blue-600
                    bg-blue-800
                    focus:shadow-outline
                    focus:outline-none
                    text-white text-xs
                    font-bold
                    py-3
                    mt-5
                    w-full
                    rounded
                  "
                  >
                    Confirm Account
                  </button>
                  <button
                    @click="promptDiv = false"
                    class="
                    float-right
                    uppercase
                    mx-auto
                    text-blue-600
                    focus:shadow-outline
                    focus:outline-none
                    text-white text-xs
                    font-bold
                    py-3
                    w-full
                    rounded
                  "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      moment,
      promptDiv: false,
      isLoading: false,
      firstPage: true,
      secondPage: false,
      supportOfficer: "",
      supportOfficers: [],
      businessDetail: [],
      merchantAddress: "",
      merchantName: "",
      merchantEmail: "",
      businessName: "",
      businessPhone: "",
      contactName: "",
      storeType: "",
      terminalID: "",
      visitationAgenda: "",
      additionalComments: "",
      dateOfScheduledVisit: "",
      payload: {},
      errors: {
        supportOfficer: "",
        merchantEmail: "",
        merchantName: "",
        businessName: "",
        businessPhone: "",
        contactName: "",
        merchantAddress: "",
        storeType: "",
        terminalID: "",
        dateOfScheduledVisit: "",
        visitationAgenda: "",
      },
    };
  },
  computed: {},
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  created() {
    this.fetchMerchantVisitationsRecords();
    this.fetchSalesAgent();
  },
  methods: {
    ...mapActions(["FETCH_MERCHANT_VISITATION", "FETCH_SALES_AGENT"]),
    async fetchMerchantVisitationsRecords() {
      this.isLoading = true;
      try {
        let response = await this.$store.dispatch("FETCH_MERCHANT_VISITATION");
        this.totalMerchantVisitations = response.data.length;
        this.totalMerchantVisitations +=
          this.totalMerchantVisitations > 99 ? "+" : "";
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },

    async fetchSalesAgent() {
      this.isLoading = true;
      try {
        let response = await this.$store.dispatch("FETCH_SALES_AGENT");
        this.supportOfficers = response.data;
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },

    async fetchBusinessAsync() {
      let search = this.merchantEmail;
      this.isLoading = true;
      try {
        let response = await this.$store.dispatch(
          "FETCH_MERCHANT_USING_EMAIL_DETAILED",
          { search }
        );
        this.merchantName = response.data[0].merchantName.trim();
        this.businessName = response.data[0].BusinessName.trim();
        this.businessPhone = response.data[0].phone.trim();
        this.merchantAddress = response.data[0].address.trim();
        this.terminalID = response.data[0].terminalId.trim();
        this.storeType = response.data[0].storeName.trim();
        this.contactName = response.data[0].merchantName.trim();

        // console.log(response.data);
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    handleNext() {
      // console.log(this.merchantAddress === "");
      if (this.supportOfficer === undefined) {
        this.errors.supportOfficer = "Support Officer is required";
      }

      if (this.merchantEmail === "") {
        this.errors.merchantEmail = "Merchant Email is required";
      }

      if (this.merchantName === "") {
        this.errors.merchantName = "Merchant name is required";
      }

      if (this.businessName === "") {
        this.errors.businessName = "Business name is required";
      }

      if (this.businessPhone === "") {
        this.errors.businessPhone = "Business phone is required";
      }

      if (this.contactName === "") {
        this.errors.contactName = "Contact name is required";
      }

      if (this.merchantAddress === "") {
        this.errors.merchantAddress = "Merchant Address is required";
      }

      if (
        this.supportOfficer !== undefined &&
        this.merchantEmail !== "" &&
        this.merchantName !== "" &&
        this.businessName !== "" &&
        this.businessPhone !== "" &&
        this.contactName !== "" &&
        this.merchantAddress !== ""
      ) {
        this.errors.supportOfficer = "";
        this.errors.merchantEmail = "";
        this.errors.merchantName = "";
        this.errors.businessName = "";
        this.errors.businessPhone = "";
        this.errors.contactName = "";
        this.errors.merchantAddress = "";
        this.firstPage = false;
        this.secondPage = true;
      } else {
      }
    },
    handleBack() {
      this.firstPage = true;
      this.secondPage = false;
    },
    handleCancel() {
      console.log("Cancelled");
      this.$router.go(-1);
    },
    handleShowPrompt() {
      if (this.storeType === "") {
        this.errors.storeType = "Store type is required";
      }

      if (this.terminalID === "") {
        this.errors.terminalID = "Terminal ID is required";
      }

      if (this.dateOfScheduledVisit === "") {
        this.errors.dateOfScheduledVisit = "Date of Schedule visit is required";
      }

      if (this.visitationAgenda === "") {
        this.errors.visitationAgenda = "Visitation agenda is required";
      }

      if (
        this.storeType !== "" &&
        this.terminalID !== "" &&
        this.dateOfScheduledVisit !== "" &&
        this.visitationAgenda !== ""
      ) {
        this.promptDiv = true;
      }
    },
    handleConfirmPrompts() {
      this.prompt = true;
    },
    async handleCreateMerchantVisit() {
      try {
        this.isLoading = true;
        this.payload.merchantSupportOfficer = this.supportOfficer;
        this.payload.merchantEmail = this.merchantEmail;
        this.payload.merchantName = this.merchantName;
        this.payload.businessName = this.businessName;
        this.payload.businessPhoneNumber = this.businessPhone;
        this.payload.contactName = this.contactName;
        this.payload.supportCluster = this.merchantAddress;
        this.payload.store = this.storeType;
        this.payload.terminalID = this.terminalID;
        this.payload.visitationDate = this.dateOfScheduledVisit;
        this.payload.visitationAgenda = this.visitationAgenda;
        this.payload.additionalComments = this.additionalComments;

        let response = await this.$store.dispatch(
          "SCHEDULE_MERCHANT_VISITATION",
          { payload: this.payload }
        );
        this.promptDiv = false;
        this.isLoading = false;
        if (response.status) {
          alert(response.message);
          this.resetForm();
        } else {
          alert(response.message);
        }
      } catch (error) {}
    },

    resetForm() {
      this.supportOfficer = "";
      this.merchantEmail = "";
      this.merchantName = "";
      this.businessName = "";
      this.businessPhone = "";
      this.contactName = "";
      this.merchantAddress = "";
      this.storeType = "";
      this.terminalID = "";
      this.dateOfScheduledVisit = "";
      this.visitationAgenda = "";
      this.additionalComments = "";
      this.secondPage = false;
      this.firstPage = true;
    },
  },
};
</script>
